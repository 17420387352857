import { useQuery } from '@apollo/react-hooks';

import {
	confluenceLocalStorageInstance as localStorage,
	PERSISTED_KEYS_ON_SERVER as persistedLocalStorageKeys,
} from '@confluence/storage-manager';
import type {
	ContentUnifiedQueryType,
	ContentUnifiedQueryVariablesType,
} from '@confluence/content-unified-query';
import { ContentUnifiedQuery } from '@confluence/content-unified-query';
import { useBooleanFeatureFlag } from '@confluence/session-data';

import { useCanConvertPageToFolder } from './useCanConvertPageToFolder';
import { useConvertPageToFolderStore } from './ConvertPageToFolderStore';

const STORAGE_KEY_DISMISSED_FOLDER_CONVERSION_BANNER =
	persistedLocalStorageKeys.PERSISTED_PAGE_TO_FOLDER_CONVERSION_BANNER_DISMISSED;

type ConvertPageToFolderBannerProps = {
	contentId: string;
	spaceKey: string;
};

export const useShowConvertPageToFolderBanner = ({
	contentId,
	spaceKey,
}: ConvertPageToFolderBannerProps) => {
	const isSpaceAliasFFEnabled = useBooleanFeatureFlag('confluence.frontend.space.alias');
	const isNewContentTopperFFEnabled = useBooleanFeatureFlag(
		'confluence.frontend.custom-sites.page-header-and-title',
	);

	const bannerDismissedKeyExists = Boolean(
		localStorage?.getItem(
			persistedLocalStorageKeys.PERSISTED_PAGE_TO_FOLDER_CONVERSION_BANNER_DISMISSED,
		),
	);

	const [convertPageToFolderState, { showBanner, hideBanner }] = useConvertPageToFolderStore();

	const { canConvertPageToFolder, error: canConvertError } = useCanConvertPageToFolder({
		contentId,
		spaceKey,
		skipQuery: bannerDismissedKeyExists,
	});

	const { data: contentUnifiedData, error: contentUnifiedError } = useQuery<
		ContentUnifiedQueryType,
		ContentUnifiedQueryVariablesType
	>(ContentUnifiedQuery, {
		skip: bannerDismissedKeyExists,
		variables: {
			contentId,
			spaceKey,
			versionOverride: null,
			isSSR: Boolean(process.env.REACT_SSR),
			includeAlias: isSpaceAliasFFEnabled,
			useNewContentTopper: isNewContentTopperFFEnabled,
		},
	});

	if (
		!bannerDismissedKeyExists &&
		canConvertPageToFolder &&
		checkBannerConditionsForPage(contentUnifiedData)
	) {
		showBanner();
	} else {
		hideBanner();
	}

	const handleOnClickClose = () => {
		localStorage?.setItem(STORAGE_KEY_DISMISSED_FOLDER_CONVERSION_BANNER, true);
		hideBanner();
	};

	const error = canConvertError || contentUnifiedError;

	return {
		showBanner: convertPageToFolderState.showConvertPageToFolderBanner,
		handleOnClickClose,
		error,
	};
};

const checkBannerConditionsForPage = (contentUnifiedData: ContentUnifiedQueryType | undefined) => {
	try {
		const dynamicValue = contentUnifiedData?.content?.nodes?.[0]?.body?.dynamic?.value;
		const documentADF = dynamicValue && JSON.parse(dynamicValue);

		// Returns true if page body is empty or only contains new lines, whitespace or Children / Page Tree macros
		const isEmptyOrHasTreeMacro = documentADF?.content?.every((node: any) => {
			// Return true for Children macro
			if (node?.type === 'extension' && node?.attrs?.extensionKey === 'children') {
				return true;
			}
			// Return true for Page Tree macro or whitespace and new lines
			if (node?.type === 'paragraph') {
				// Return true for new line
				if (!node?.content) {
					return true;
				}

				// Return true for Page tree macro or whitespace
				return node?.content?.every((node: any) => {
					if (node?.type === 'text' && node?.text.trim() === '') {
						return true;
					}
					if (node?.type === 'inlineExtension' && node?.attrs?.extensionKey === 'pagetree') {
						return true;
					}
					return false;
				});
			}
			return false;
		});

		return isEmptyOrHasTreeMacro;
	} catch {
		return false;
	}
};
