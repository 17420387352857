import React from 'react';
import PropTypes from 'prop-types';

import { useContentUnifiedQuery } from '@confluence/content-unified-query';
import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import { useBooleanFeatureFlag } from '@confluence/session-data';
import { StickyBanner } from '@confluence/content-header';
import { useContentType } from '@confluence/page-context';
import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';
import {
	useConvertPageToFolderStore,
	BANNER_HEIGHT,
} from '@confluence/folder/entry-points/useConvertPageToFolderStore';

import { ContentScreenComponent } from './ContentScreenComponent';
import { ContentContainerComponent } from './ContentContainerComponent';

export const ContentContainer = ({
	contentId,
	fetchTheme,
	children,
	isScreenContentView,
	isContainerContentView,
	spaceKey,
	errorAttribution = Attribution.BACKBONE as string,
	contentScreenStyles,
	stickyHeader,
}) => {
	const isSpaceAliasFFEnabled = useBooleanFeatureFlag('confluence.frontend.space.alias');
	const isNewContentTopperFFEnabled = useBooleanFeatureFlag(
		'confluence.frontend.custom-sites.page-header-and-title',
	);

	const isLivePage = useIsLivePage();
	const [contentType] = useContentType();

	const [convertPageToFolderState] = useConvertPageToFolderStore();

	const queryResult = useContentUnifiedQuery({
		commonConfig: {
			context: { single: true }, // prevents batching
		},
		contentUnifiedQueryConfig: {
			skip: !fetchTheme || !contentId,
			variables: {
				contentId,
				spaceKey,
				versionOverride: null,
				includeAlias: isSpaceAliasFFEnabled,
				useNewContentTopper: isNewContentTopperFFEnabled,
			},
		},
		pageUnifiedQueryConfig: {
			skip: !fetchTheme || !contentId,
			variables: {
				contentId,
				includeAlias: isSpaceAliasFFEnabled,
				useNewContentTopper: isNewContentTopperFFEnabled,
			},
		},
		knownContentType: contentType,
	});

	if (queryResult.error) {
		return (
			<ErrorBoundary attribution={errorAttribution}>
				{children}
				<ErrorDisplay error={queryResult.error} />
			</ErrorBoundary>
		);
	} else {
		const theme = Boolean(queryResult?.data?.space?.theme);
		const lookAndFeel = queryResult?.data?.space?.lookAndFeel || {};

		return (
			<ErrorBoundary attribution={errorAttribution}>
				<ContentScreenComponent
					isThemed={theme}
					isLoading={queryResult.loading}
					lookAndFeel={lookAndFeel}
					isContentView={isScreenContentView}
					contentScreenStyles={contentScreenStyles}
					isLivePage={isLivePage}
				>
					{!theme && stickyHeader && <StickyBanner>{stickyHeader}</StickyBanner>}
					<ContentContainerComponent
						isThemed={theme}
						lookAndFeel={lookAndFeel}
						isContentView={isContainerContentView}
						isLoading={queryResult.loading}
						isLivePage={isLivePage}
						additionalPaddingTop={
							convertPageToFolderState.showConvertPageToFolderBanner ? BANNER_HEIGHT : 0
						}
					>
						{typeof children === 'function'
							? children({ isThemed: theme, isLoading: queryResult.loading })
							: children}
					</ContentContainerComponent>
				</ContentScreenComponent>
			</ErrorBoundary>
		);
	}
};

ContentContainer.propTypes = {
	contentId: PropTypes.string,
	fetchTheme: PropTypes.bool,
	spaceKey: PropTypes.string,
	children: PropTypes.any,
	isScreenContentView: PropTypes.bool,
	isContainerContentView: PropTypes.bool,
	errorAttribution: PropTypes.string,
	stickyHeader: PropTypes.any,
	contentScreenStyles: ContentScreenComponent.propTypes.contentScreenStyles,
};
